html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue','sans-serif';
  color: #444;
  background: hsl(200,6%,10%);
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: hsl(200, 5%, 5%);
}

body::-webkit-scrollbar-thumb {
  background-color: hsl(200, 5%, 20%);
  border-radius: 4px;
}